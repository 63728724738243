import z from 'zod'

export const AuthErrorResponseSchema = z.object({
	errorCode: z.string(),
	reasonCode: z.number().optional(),
})

export const AuthGetPreferencesRequestSchema = z.object({
	uacfId: z.string(),
	idmAccessToken: z.string(),
})

export const AuthGetPreferencesResponseSchema = z.object({
	lastUpdated: z.string().nullable().optional(),
	preferences: z.object({
		activities: z.string().array(),
		athletes: z.string().array(),
		genders: z.string().array(),
	}),
	sizePreferences: z
		.object({
			gender: z
				.enum(['Mens', 'Womens', 'Boys', 'Girls', 'Unisex', 'Adult Unisex', 'Youth Unisex', 'Unknown'])
				.catch('Unknown'),
			productType: z.enum(['Tops', 'Bottoms', 'Footwear', 'Bras', 'Unknown']).catch('Unknown'),
			size: z.string(),
			sizeType: z
				.enum(['Apparel', 'Footwear', 'Waist', 'WaistInseam', 'Bandcup', 'SizeExtension', 'Youth', 'Unknown'])
				.catch('Unknown'),
			extendedSize: z
				.string()
				.nullish()
				.transform((s) => s ?? undefined),
		})
		.array(),
})

export const AuthUpdatePreferencesRequestSchema = z.object({
	locale: z.string(),
	uacfId: z.string(),
	idmAccessToken: z.string(),
	username: z.string().nullable().optional(),
	password: z.string().nullable().optional(),
	currentEmail: z.string().optional(),
	currentPassword: z.string().optional(),
	preferences: z
		.object({
			activities: z.string().array(),
			athletes: z.string().array(),
			genders: z.string().array(),
		})
		.optional(),
	sizePreferences: z
		.object({
			gender: z
				.enum(['Mens', 'Womens', 'Boys', 'Girls', 'Unisex', 'Adult Unisex', 'Youth Unisex', 'Unknown'])
				.catch('Unknown'),
			productType: z.enum(['Tops', 'Bottoms', 'Footwear', 'Bras', 'Unknown']).catch('Unknown'),
			size: z.string(),
			sizeType: z
				.enum(['Apparel', 'Footwear', 'Waist', 'WaistInseam', 'Bandcup', 'SizeExtension', 'Youth', 'Unknown'])
				.catch('Unknown'),
			extendedSize: z
				.string()
				.nullish()
				.transform((s) => s ?? undefined),
		})
		.array()
		.optional(),
})

export const AuthClientTokenResponseSchema = z.object({
	accessToken: z.string(),
	expiresIn: z.string(),
	tokenType: z.string(),
})

export const AuthLoginRequestSchema = z.object({
	email: z.string(),
	password: z.string(),
	autoCreateAccountLink: z.boolean().default(true),
})

export const AuthLoginResponseSchema = z.object({
	accessToken: z.string(),
	expiresIn: z.string(),
	refreshToken: z.string(),
	tokenType: z.string(),
})

export const AuthRefreshRequestSchema = z.object({
	refreshToken: z.string(),
})

export const AuthRefreshReponseSchema = AuthLoginResponseSchema

export const AuthRegisterRequestSchema = z.object({
	locale: z.string(),
	password: z.string(),
	region: z.string(),
	username: z.string(),
})

// Note: potential deprecation in favor of switch
// https://github.com/colinhacks/zod/issues/2106
export const AuthResetPasswordRequestSchema = z.discriminatedUnion('operation', [
	z.object({ operation: z.literal('initiate'), email: z.string(), locale: z.string() }),
	z.object({ operation: z.literal('peek'), idmToken: z.string(), locale: z.string() }),
	z.object({ operation: z.literal('reset'), encodedPassword: z.string(), idmToken: z.string(), locale: z.string() }),
])

export const GiftCardBalanceRequestSchema = z.object({
	cardNumber: z.string(),
	pin: z.string(),
})

export const GiftCardBalanceResponseSchema = z.object({
	balance: z.number().optional(),
	error: z.string().optional(),
})

export const InvalidateIsrRequestSchema = z.object({
	paths: z.string().array(),
})

export const LoyaltyEstimatePointsRequestSchema = z.object({
	customerNo: z.string(),
	locale: z.string(),
	products: z
		.object({
			productID: z.string(),
			quantity: z.number(),
			price: z.number(),
		})
		.array(),
	value: z.number(),
})

export const PreferenceSettingsResponseSchema = z.object({
	id: z.string(),
	title: z.string(),
	categories: z
		.object({
			id: z.string(),
			title: z.string(),
			options: z
				.object({
					id: z.string(),
					title: z.string(),
					categories: z.object({ id: z.string(), name: z.string() }).array(),
					imageUrl: z.string().optional().default(''),
				})
				.array(),
		})
		.array(),
})

/* eslint-disable spaced-comment */
import type { UaI18n, DomainItem, UaConfigPublic } from '~/config/app/types'
import { getPublicConfig } from '~/lib/client-server/config'
import { ensureString } from '~/types/strict-null-helpers'

/**
 * Configuration for the UA app should be managed through this static object.  While
 * not everything in the app is using this, we should use it for any new configuration
 * data going forward and eventually migrate the old stuff to it.
 *
 */
export class UaConfigPublicImpl {
	///// DIRECT PROPERTY ACCESS //////
	static get bazaarVoiceLocales() {
		return getPublicConfig().bazaarvoice.locales
	}

	static get ocapiUrl(): string | undefined {
		return getPublicConfig().uacapi.ocapi ?? undefined
	}

	static get environment() {
		return ensureString(process.env.CONFIG_ENVIRONMENT) as 'development' | 'staging' | 'production'
	}

	static get region() {
		return ensureString(process.env.CONFIG_REGION)
	}

	static get isPreview(): boolean {
		return this.isDevelopment && process.env.VERCEL_ENV === 'preview' && process.env.VERCEL_GIT_COMMIT_REF !== 'main'
	}

	static get isDevelopment(): boolean {
		return this.environment === 'development'
	}

	static get isProduction(): boolean {
		return this.environment === 'production'
	}

	static get isStaging(): boolean {
		return this.environment === 'staging'
	}

	static get configRegion(): string {
		return ensureString(process.env.CONFIG_REGION) as 'na' | 'emea'
	}

	static get isEMEA(): boolean {
		return this.configRegion === 'emea'
	}

	static get cartSharingEnabled(): boolean {
		return getPublicConfig().cart.sharing_enabled
	}

	static get idMeEnabled(): boolean {
		return getPublicConfig().idme.enabled
	}

	static get isGiftCardPaymentEnabled(): boolean {
		return getPublicConfig().gift_card_payment.enabled
	}

	static get experian(): UaConfigPublic['experian'] {
		return getPublicConfig().experian
	}

	/**
	 * Gets the locale's associated with the current config
	 */
	static get locales(): UaI18n['locales'] {
		return getPublicConfig().i18n.locales
	}

	/**
	 * Gets the default locale from the entire configuration.  This is
	 * used in cases where the locale is not specified in the URL at all and
	 * the domain is not recognized or the domain is recognized but the locale
	 * is not specified.
	 */
	static get defaultLocale() {
		return getPublicConfig().i18n.default_locale
	}

	/**
	 * Gets the default domain from the configuration.  This is used in cases
	 * where we need to build a full URL but, for some reason, we don't have
	 * the requested domain.
	 */
	static get defaultDomain() {
		return getPublicConfig().i18n.default_domain
	}

	/**
	 * Gets the preview domain from the build configuration.  This is used in cases
	 * where we need the preview specific domain for use server-side without inference
	 */
	static get previewDomain() {
		return getPublicConfig().i18n.preview_domain
	}

	/**
	 * Gets the supported domains from the configuration
	 */
	static get domains() {
		return getPublicConfig().i18n.domains
	}

	static get uacapiUrl() {
		return getPublicConfig().uacapi.url
	}

	static get appName() {
		return getPublicConfig().app.name
	}

	static get appVersion() {
		return getPublicConfig().app.version
	}

	static get uacapiDebug() {
		return getPublicConfig().uacapi.debug
	}

	static get uacapiMaxRetryCount() {
		return getPublicConfig().uacapi.max_retry_count
	}

	static get coremediaUrl() {
		return getPublicConfig().cms.coremedia_graphql_url
	}

	static get coremediaPreviewUrl() {
		return getPublicConfig().cms.coremedia_preview_graphql_url
	}

	static get klarnaUrl() {
		return getPublicConfig().klarna.url
	}

	static get klarnaClientId() {
		return getPublicConfig().klarna.client_id
	}

	static get previewSecretToken() {
		return getPublicConfig().cms.preview_access_token
	}

	static get salesforceToolkitUrl() {
		return getPublicConfig().toolkit.salesforce_toolkit_url
	}

	static get toolkitEnabled() {
		return getPublicConfig().toolkit.enabled
	}

	static get oktaClientId(): string {
		return getPublicConfig().okta.client_id
	}

	static get getPaazl() {
		return getPublicConfig().paazl
	}

	/**
	 * Locale-specific configurations.
	 */
	static stateSelectionEnabled(locale: string): boolean {
		return getPublicConfig().addresses.state_selection_enabled.locales.includes(locale) ?? false
	}

	static displayAddressInformation(locale: string): boolean {
		return getPublicConfig().addresses.display_address_information.locales.includes(locale) ?? false
	}

	static address2Enabled(locale: string): boolean {
		return getPublicConfig().addresses.address2_enabled.locales.includes(locale) ?? false
	}

	///// QUERYING, FILTERING, TRANSFORMATION //////

	/**
	 * Determiines if the given locale is recognized by the configuration file.
	 * @param locale
	 * @returns
	 */
	static isValidLocale(locale: string | undefined): boolean {
		return locale ? this.locales.map((l) => l.locale).includes(locale) : false
	}

	/**
	 * Based on the given hostname, determine which locale should be used by default.
	 * @param host
	 * @returns
	 */
	static getDefaultLocaleFromHost(host?: string): string | undefined {
		return host ? this.domains?.find((d) => d.domain === host)?.default_locale : undefined
	}

	/**
	 * Returns the domain object for the given locale.  The domain object contains information
	 * about how the domain should be routed along with information about the locales associated with
	 * the domain.
	 * @param locale
	 * @returns
	 */
	static getDomainObjectFromLocale(locale: string): DomainItem | undefined {
		const detectedDomain = this.domains?.find((d) => d.locales.includes(locale))
		return detectedDomain ?? this.domains?.find((d) => d.domain === getPublicConfig().i18n.default_domain)
	}

	/**
	 * Gets the locales associated with the given native app.
	 * @param app
	 * @returns
	 */
	static getLocalesForApp(app: string): string[] {
		return getPublicConfig().uaapps[app]?.locales || []
	}

	/**
	 * A convenience method for getting the locale strings only, not the entire
	 * locale object.
	 * @returns
	 */
	static getLocalesAsStrings() {
		return this.locales.map((l) => l.locale)
	}

	static isProfileSectionEnabled(section: 'follow_section'): boolean {
		return getPublicConfig().profile.preferences[section]
	}
}

import type { NextApiRequest } from 'next'
import type { NextRequest } from 'next/server'
import { unique } from '~/lib/arrays'
import { ensureString } from '~/types/strict-null-helpers'
import { UaConfigPublicImpl } from '../client-only/ua-config'

/**
 * Get the list of locales that are supported based on configuration
 * @returns Default to just an array of one item (en-us) if configuration is empty
 */
export function getSupportedLocales(): string[] {
	return UaConfigPublicImpl.locales?.map((l) => l.locale) || ['en-us']
}

/**
 * Returns true if the given locale is known, false otherwise
 * @param localeStr The locale to search for
 * @returns
 */
export function isValidLocale(localeStr: string | undefined): boolean {
	return UaConfigPublicImpl.isValidLocale(localeStr)
}

/**
 * Returns the language portion of the given locale only if it is
 * supported in our list of languages.  Otherwise it returns
 * 'en'.
 * @param locale The locale to search by
 * @returns Returns the language code or 'en' if not found.
 */
export function getLanguageByLocale(locale: string): string {
	if (UaConfigPublicImpl.isValidLocale(locale)) {
		return locale.split('-')[0]
	}
	return 'en'
}

/**
 * Returns the configured default locale.
 */
export const getDefaultLocale = () => UaConfigPublicImpl.defaultLocale

/**
 * Gets the supported languages by getting the list of locales and splitting out the
 * language portion from each.  The list is guaranteed to have unique values.
 * @returns An array of language codes (e.g. ['en', 'fr'])
 */
export const getSupportedLanguages = () => unique(UaConfigPublicImpl.locales.map((l) => l.locale.split('-')[0]))

/**
 * Given a locale, returns the list of alternate languages that our config
 * supports.  The alternate languages are the language portions of the other
 * locales that share the same country.
 *
 * @param locale The locale to search by
 * @returns Returns the language code or 'en' if not found.
 */
export const getAlternateLanguages = (locale: string): string[] => {
	const [currentLanguage, currentCountry] = locale.split('-')
	return getSupportedLocales()
		.map((locale) => locale.split('-'))
		.filter(([language, country]) => country === currentCountry && language !== currentLanguage)
		.map(([language, _]) => language)
}

/**
 * Get the domain associated with the given locale based on configuration
 * @param locale The locale string (e.g en-us)
 * @returns
 */
export function getDomainFromLocale(locale: string): string {
	return UaConfigPublicImpl.getDomainObjectFromLocale(locale)?.domain || UaConfigPublicImpl.defaultDomain
}

export function getDefaultLocaleFromHost(host?: string): string | undefined {
	return UaConfigPublicImpl.getDefaultLocaleFromHost(host)
}

/**
 * Gets the country code from a locale (e.g. if en-us is passed in then us is returned)
 * @param locale
 * @returns
 */
export const getCountryCodeByLocale = (locale: string): string => locale.split('-')?.[1] || 'us'

const isoCodes = {
	ca: 'CAN',
	us: 'USA',
	at: 'AUT',
	be: 'BEL',
	dk: 'DNK',
	fr: 'FRA',
	de: 'DEU',
	gb: 'GBR',
	ie: 'IRL',
	it: 'ITA',
	nl: 'NLD',
	es: 'ESP',
	se: 'SWE',
}

export const getISOByLocale = (locale: string): string => isoCodes[getCountryCodeByLocale(locale)]

/**
 * Gets the ISO Country code from the supplied country code based on the locales that are supported.
 */
export const getISOByCountryCode = (countryCode = 'us'): string => isoCodes[countryCode.toLowerCase()]

/**
 * Gets the supported countries based on the locales that are supported.
 * @returns An array of country codes.
 */
export const getSupportedCountries = () => unique(getSupportedLocales().map((locale) => getCountryCodeByLocale(locale)))

/**
 * Returns a lowercase language code array for a given country
 * @param country The country to find languages
 * @returns
 */
export const getLanguagesForCountry = (country: string) =>
	getSupportedLocales()
		.filter((l) => getCountryCodeByLocale(l).toLowerCase() === country.toLowerCase())
		.map((l) => getLanguageByLocale(l))

/**
 * Given a country code (e.g. US or us), this will find all the countries that are considered
 * "nearby" (based on configuration).  For example, if you pass in "us", it might return
 * ["ca", "mx"] depending on the configuration setting `public.i18n.nearby` .
 *
 * @param country The country to find nearby countries for
 */
export const getNearbyCountries = (country: string) =>
	unique(getSupportedCountries().filter((c) => c.toLowerCase() !== country.toLowerCase()))

export type CountryUrlMap = Record<string, Record<string, string>>

/**
 * This will build a map that looks something like this:
 * ```
 * 	{
 * 		us: {
 *			en-us: 'https://www.underarmour.com/en-us/',
 *		},
 *		ca: {
 *			'en-ca': 'https://www.underarmour.ca/en-ca/',
 *			'fr-ca': 'https://www.underarmour.ca/fr-ca/',
 *		},
 *		mx: {
 *			`es-mx`: 'https://www.underarmour.com.mx/es-mx/',
 *		},
 * 	}
 * ```
 * @returns
 */
export const buildCountrySitesMap = () =>
	getSupportedLocales().reduce((acc, l) => {
		const country = getCountryCodeByLocale(l).toLowerCase()
		if (!(country in acc)) {
			acc[country] = {} as Record<string, string>
		}
		acc[country][l] = `https://${getDomainFromLocale(l)}/${l}/`

		// We automatically create a "default" value that will be used
		//	when selecting the URL to send the user to when they are
		//	selecting only a country (and not a specific locale)
		if (!acc[country].default) {
			acc[country].default = acc[country][l]
		}
		return acc
	}, {} as CountryUrlMap)

/**
 * This will return the locales that are supported for the given UA apps (e.g. Shop App or MyMap suite)
 * @param app The app to get the locales for (e.g. mapmy or shop)
 * @returns This will return an array of locales or an empty array if no locales are supported for the app or if the app isn't recognized.
 */
export const getSupportedLocalesForApp = (app: string): string[] => UaConfigPublicImpl.getLocalesForApp(app)

/**
 * This returns a non-standard variant locale string {language}_{country} (e.g. en_US) for the given locale.
 * The format is unlike the format used in standard Javascript Intl.Locale objects. That format, and most other implementations of
 * it are in IETF BCP 47 format (e.g. en-US or en-us). This format is used by some APIs.
 */
export function getLocaleVariantFormat(locale: string): string {
	const supportedLocale = getSupportedLocales().find((l) => l === locale) ?? getDefaultLocale()
	const [language, region] = supportedLocale.split('-')
	return `${language}_${region.toUpperCase()}`
}

/**
 * This will return the locale that our request referrer is using.  If the referrer is not set, then
 * it will return the default locale.
 */
export const getLocaleFromNextApiRequest = (req: NextApiRequest): string => {
	if (req.method === 'POST' && req.body?.locale) return ensureString(req.body.locale)
	if (req.query?.locale)
		return getSupportedLocales().find((locale) => locale === req.query.locale) ?? getDefaultLocale()
	if (req.headers?.referrer) return new URL(req.headers.referrer as string).pathname.split('/')[1]
	const hostLocale = getDefaultLocaleFromHost(req.headers?.host)
	return hostLocale ?? getDefaultLocale()
}

export const getLocaleFromNextRequest = (req: NextRequest, json?: Record<string, string>): string => {
	if (req.method === 'POST' && json?.locale) {
		return ensureString(json.locale)
	}

	if (req.nextUrl.searchParams.has('locale')) {
		const localeInQueryParam = req.nextUrl.searchParams.get('locale')
		return getSupportedLocales().find((locale) => locale === localeInQueryParam) ?? getDefaultLocale()
	}

	if (req.headers?.has('referrer')) {
		const referrer = req.headers.get('referrer') as string
		return new URL(referrer).pathname.split('/')[1]
	}

	return getDefaultLocale()
}

export const validateLocale = (locale?: string) =>
	locale && UaConfigPublicImpl.getLocalesAsStrings().includes(locale ?? '') ? locale : getDefaultLocale()

export const getSiteCodeByLocale = (locale: string): string => {
	const [_, siteCode] = locale.split('-')
	// The greater EMEA region uses non-standard site codes that don't map 1:1 with the region value of a locale
	if (UaConfigPublicImpl.isEMEA) {
		const emeaSiteCodes = {
			'en-nl': 'EU',
			'nl-nl': 'EU',
			'en-ie': 'UKIE',
		}

		return emeaSiteCodes[locale] || siteCode.toUpperCase()
	}
	return siteCode.toUpperCase()
}

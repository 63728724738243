import { isNonNullish } from '~/types/strict-null-helpers'
import { getPublicConfig } from '../client-server/config'

/**
 * Register config based feature flags in this map so they can be
 * accessed from the useFeatureFlags hook.
 * NOTE: If the name of the config based feature flag has the same name as
 * a flag originating from SFCC or Flagsmith, then the flag defined here will
 * override it.
 */
const configFeatureFlagMap = {
	hasPdpEnhancedBuyPanel: getPublicConfig().pdp.has_enhanced_buy_panel,
	hasPdpEnhancedImages: getPublicConfig().pdp.has_enhanced_images,
	hasPdpEnhancedProductDetails: getPublicConfig().pdp.has_enhanced_product_details,
	hasPdpEnhancedProductName: getPublicConfig().pdp.has_enhanced_product_name,
	hasPdpEnhancedContent: getPublicConfig().pdp.has_enhanced_content,
	hasPdpJumplink: getPublicConfig().pdp.has_jumplink,
} as const

export type ConfigFeatureFlags = {
	[Property in keyof typeof configFeatureFlagMap]?: boolean
}

export function getConfigFeatureFlags() {
	return Object.keys(configFeatureFlagMap).reduce<ConfigFeatureFlags>((flags, key) => {
		if (isNonNullish(configFeatureFlagMap[key])) return { ...flags, [key]: configFeatureFlagMap[key] }
		return flags
	}, {})
}

const REGEX_LOCALE = /^\/{0,1}([A-Za-z]{2}-[A-Za-z]{2})\//

/**
 * This will take a relative path such as /test/path or test/path and
 * return a url that is preceded by a locale (e.g. /test/path becomes /en-us/test/path)
 * Note: This will handle the case where you pass in preceding slash or not but it
 * not do anything with the trailing slash. That is, if you pass in /test/path it will
 * always returns /[locale]/test/path and not /[locale]/test/path/  and the reverse is
 * true.
 * @param pathWithoutLocale This can be any relative URL (with or without preceding slash)
 * @param locale The locale to prefix.
 * @returns
 */
export const getLocaleUrl = (pathWithoutLocale: string, locale: string) => {
	if (pathWithoutLocale === '') {
		return `/${locale}`
	}

	const normalizedPath = pathWithoutLocale
		.split('/')
		.filter((p) => !!p)
		.join('/')

	return `/${locale}/${normalizedPath}${pathWithoutLocale.length > 1 && pathWithoutLocale.at(-1) === '/' ? '/' : ''}`
}

/**
 * This will extract the portion of the path that does not have a locale
 * specified in the form of an array of segments.  So for example if you
 * pass in /en-us/path/segment, it will return [path, segment]
 * @param pathname
 * @param locale
 * @returns
 */
export const getNonLocaleURLSegments = (pathname: string): string[] => {
	const localeFound = pathname.match(REGEX_LOCALE)?.at(1) || ''
	return pathname.split('/').filter((p) => p && (!localeFound || localeFound !== p))
}

/**
 * This will extract the portion of the path that does not have a locale
 * specified in the form of a string.  So for example if you pass in
 * /en-us/path/segment, it will return /path/segment.  If you pass in an empty
 * path it will return '/' and if you pass in '/en-us' it will return '/'
 * @param pathname
 * @param locale
 * @returns
 */
export const getUrlWithoutLocale = (pathname: string): string => {
	let path = getNonLocaleURLSegments(pathname).join('/')
	if (path.length === 0 || path[0] !== '/') {
		path = `/${path}`
	}

	if (pathname.length > 1 && pathname.at(-1) === '/' && path.at(-1) !== '/') {
		path = `${path}/`
	}

	return path
}
/**
 * This will get the locale from the path.  So for example if you pass in
 * /en-us/path/segment, it will return en-us
 * @param pathname
 * @returns The locale or undefined if not found.
 */
export const getLocaleFromPath = (pathname: string): string | undefined =>
	pathname.match(REGEX_LOCALE)?.at(1) || undefined

/**
 * Convenience function to get a relative URL for a given path, locale and optional searchParams.
 * @param locale The locale to use
 * @param pathname The path to use
 * @param searchParams Search params to append or override
 * @returns A relative path
 */
export function getRelativeUrlFromLocaleAndPathname(
	locale: string,
	pathname: string,
	searchParams?: URLSearchParams,
): string {
	const path = getUrlWithoutLocale(pathname)
	const url = new URL(`https://www.underarmour.com/${locale}${path}`)
	searchParams?.forEach((v, k) => url.searchParams.set(k, v))
	return url.toString().replace(/^.*\/\/[^/]+/, '')
}

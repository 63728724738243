import {
	CustomerAuthType,
	GetUserProfileDocument,
	type GetUserProfileQuery,
	type RegisteredCustomer,
} from '~/graphql/generated/uacapi/type-document-node'
import { CUSTOMER_BOPIS_CONTACT_STORAGE_KEY, CUSTOMER_PHONE_STORAGE_KEY, IDME_STORAGE_KEY } from '~/lib/constants'
import { getCountryCodeByLocale } from '~/lib/i18n/locale'
import { ensureNonNullishArray, ensureString } from '~/types/strict-null-helpers'
import { decodeUacapiToken, uacapiAuthGuest, uacapiAuthRegistered, uacapiLogout } from '../../api/uacapi'
import { initApolloClient } from '../../client-server/uacapi-client'
import { createClientLogger } from '../../logger'
import { getStoredProfile, getStoredSession, setStoredProfile, storeSessionData } from './storage'
import { AuthenticationError, LoginError, isAuthenticationError } from './types'
import type { UserProfileInfo, UserSessionInfo } from './types'
import { isTokenExpired } from '~/lib/auth'
import { hashUserEmail } from '~/lib/search'
import { zodParseBodyJson } from '~/lib/schemas/utils'
import { AuthLoginResponseSchema, AuthRefreshReponseSchema } from '~/lib/schemas/api'

const logger = createClientLogger('auth')

function logError(error: unknown) {
	if (isAuthenticationError(error)) {
		logger.error(`${error.type} - ${error.message}`)
	} else if (error instanceof Error) {
		logger.error(error.message)
	}
}

/**
 * Take the issuedAt date from a token and return
 * true if now is 5 minutes or more after that date else false
 * @param  {Date} date
 * @returns boolean
 */
function isTokenCreatedAfterBuffer(date: Date): boolean {
	const issuedAt = Math.floor(date.getTime())
	const now = Math.floor(new Date().getTime())
	// TODO: could make the value 300000 (5 min) a config option
	return (date && now - issuedAt) > 300000
}

/**
 * Here is the flow of the authentication process:
 * 1. Get the current uacapi token
 * 2. Decode the token
 * 3. Get signed credentials
 * 4. Get the authorization code from IDM
 * 5. Get the IDM token
 * 6. Check the uacapi token expiration
 * 7. Get the new uacapi token by exchanging the IDM token for a UACAPI token
 * 8. Decode the token
 *
 * @param locale The locale from which this request is being made
 * @param email The email address (or username)
 * @param password The password
 * @param rememberMe Whether to store this information for a period of time.
 * @returns
 */
async function authenticateWithEmail({
	locale,
	email,
	password,
	rememberMe = false,
	autoCreateAccountLink,
}: {
	locale: string
	email: string
	password: string
	rememberMe?: boolean
	autoCreateAccountLink?: boolean
}): Promise<UserSessionInfo> {
	// get the current uacapi token
	const session = await getSession(locale)
	if (!session) {
		// We can't start a logged in session without an existing guest session.  Something
		//  has gone wrong that we can't recover from
		logger.error('Unable to start the authentication process because we were unable to determine the guest session')
		throw new Error('Failed to start authentication process')
	}

	// NOTE: We actually do a login with our authentication service
	//  At the time of this writing that would be IDM but it can also
	//  be Auth0, Okta or any other service that is used to authenticate.
	const loginResponse = await fetch('/api/auth/v2/login/', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ email, password, autoCreateAccountLink }),
	})

	// if a user has X attempts in X time the IP will be blocked for X time
	// after that any valid login will return a 406 or 418
	if (loginResponse.status === 406 || loginResponse.status === 418) {
		throw new LoginError({ url: loginResponse.url, reasonCode: loginResponse.status, errorCode: 'not_acceptable' })
	}

	if (!loginResponse.ok) {
		const errorPayload = await loginResponse.json()
		throw new LoginError(errorPayload)
	}

	const loginResponseJson = await zodParseBodyJson(AuthLoginResponseSchema, loginResponse)

	// get the new uacapi token by exchanging the IDM token for a UACAPI token
	const newUacapiToken = await uacapiAuthRegistered({
		locale,
		accessToken: loginResponseJson.accessToken,
		refreshToken: loginResponseJson.refreshToken,
		accessTokenExpiration: loginResponseJson.expiresIn,
		...(!isTokenExpired(session.uacapi.expiresAt) ? { previousToken: session.uacapi.accessToken } : {}),
	})

	if (!newUacapiToken) {
		throw new AuthenticationError('uacapi', 'Unable to get exchange the IDM token for a registered user UACAPI token')
	}

	// decode the token
	if (!newUacapiToken.decodedToken) {
		throw new AuthenticationError('uacapi', 'Unable to decode the UACAPI token')
	}

	const newSession = {
		accountType: CustomerAuthType.REGISTERED,
		customerNo: newUacapiToken.decodedToken.subjectDecoded?.customerNo,
		rememberMe,
		uacapi: {
			accessToken: newUacapiToken.accessToken,
			expiresAt: new Date(newUacapiToken.expiresAt),
			iat: newUacapiToken.decodedToken.payload.iat,
		},
		idm: {
			accessToken: loginResponseJson.accessToken,
			refreshToken: loginResponseJson.refreshToken,
			expiresAt: new Date(loginResponseJson.expiresIn),
		},
	}

	storeSessionData(newSession)

	return newSession
}

/**
 * Authenticate as a guest user.  This will attempt to get a a guest user token.  If the
 * user already has a guest session it will attempt to use the previous token to get a new
 * one.  The previous one is used to tell UACAPI about the previous state of the user's session
 * so that it can be restored.  Things like the cart and copied to the new session so that the user
 * is less impacted by the change.
 *
 * @param locale
 * @param currentSession
 * @returns
 */
async function authenticateAsGuest(
	locale: string,
	currentSession?: UserSessionInfo,
): Promise<UserSessionInfo | undefined> {
	try {
		const { accessToken, expiresAt } = await uacapiAuthGuest({
			locale,

			// need to include the previous token if exists
			// so that UACAPI can re-use the Session in Redis DB
			// otherwise the Session associated with this accessToken
			// becomes abandoned and takes up resources until expiration
			previousToken: currentSession?.uacapi.accessToken,
		})
		const decodedToken = decodeUacapiToken(accessToken)
		const newSession = {
			accountType: CustomerAuthType.GUEST,
			customerNo: decodedToken?.subjectDecoded.customerNo || '',
			rememberMe: false,
			uacapi: {
				accessToken,
				expiresAt: new Date(expiresAt),
				iat: decodedToken?.payload.iat,
			},
			// NOTE: There's no IDM token information stored here because we only
			//  need that if the user is registered.
		}
		storeSessionData(newSession)
		return newSession
	} catch (e) {
		logError(e)
		return undefined
	}
}

async function registerUser(locale: string, email: string, password: string): Promise<void> {
	const region = getCountryCodeByLocale(locale)
	// TODO: Should we add logic to handle the news/promotions checkbox? It seems to be missing.
	const res = await fetch('/api/auth/v2/register/', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			username: email,
			password,
			locale,
			region,
		}),
	})

	if (res.status !== 201) {
		const registrationResponseJson = await res.json()
		throw new Error(registrationResponseJson)
	}
}

function sessionMatchesProfile(session: UserSessionInfo, profile?: UserProfileInfo) {
	// All this is checking is to see if the given session and the given profile
	//	are both guest or registered.  We currently don't have a way of checking to
	//	see if the session matches the profile.  For that, we will need to add some
	//	kind of session id to the profile.
	return session.idm?.accessToken && profile?.uacfId
}

/**
 * Get the user profile information from UACAPI.  This will work whether
 * the user is logged in or not (guest users can have profiles too - although
 * they are limited to customer groups for the most part)
 * @param locale
 * @param session
 * @returns
 */
async function getUserProfile(locale: string, session: UserSessionInfo): Promise<UserProfileInfo | undefined> {
	// First, check to see if we have the right profile in the local storage.  If we do then
	//  use that.

	// TODO: How do we update this to ensure that we have the latest profile?
	const storedProfile = getStoredProfile()

	// TODO: Validate that the profile isn't for a different session
	if (storedProfile && sessionMatchesProfile(session, storedProfile)) {
		return storedProfile
	}

	// Get the profile for this registered user from UACAPI
	const apolloClient = initApolloClient(locale, undefined)
	const { data } = await apolloClient.query<GetUserProfileQuery>({
		query: GetUserProfileDocument,
		context: { headers: { authorization: `Bearer ${session.uacapi.accessToken}` } },
		fetchPolicy: 'no-cache',
	})

	// Typeguard to ensure the type is correct
	if (data.viewer) {
		const userInfo = data.viewer as RegisteredCustomer
		const profileDataToStore: UserProfileInfo = {
			firstName: userInfo.firstName || undefined,
			lastName: userInfo.lastName || undefined,
			email: ensureString(userInfo.email),
			emailHash: await hashUserEmail(ensureString(userInfo.email)),
			phone: userInfo.phone || undefined,
			postalCode: userInfo.postalCode || undefined,
			isEmployee: userInfo.isEmployee || undefined,
			isVIP: userInfo.isVIP || undefined,
			gender: userInfo.gender || undefined,
			birthday: userInfo.birthday || undefined,
			uacfId: userInfo.uacfId || undefined,
			vip: userInfo.vip
				? {
						availablePoints: userInfo.vip?.availablePoints ? parseInt(userInfo.vip?.availablePoints, 10) : 0,
						vipAccountId: userInfo.vip?.vipAccountId || '',
				  }
				: undefined,
			selectedStore: {
				id: ensureString(userInfo.selectedStore?.id),
				name: ensureString(userInfo.selectedStore?.name),
			},
			uiHints: {
				isLoyaltyEnabled: userInfo.uiHints?.isLoyaltyEnabled || undefined,
				useBOPIS: userInfo.uiHints?.useBOPIS || undefined,
			},
			customerGroups: ensureNonNullishArray(
				userInfo.customerGroups?.map((c) => ({ UUID: ensureString(c.UUID), id: ensureString(c.id) })),
			),
			loyalty: userInfo.loyalty?.status
				? {
						id: userInfo.loyalty.ID,
						status: userInfo.loyalty.status,
						statusDate: userInfo.loyalty?.statusDate ? new Date(userInfo.loyalty.statusDate) : undefined,
				  }
				: undefined,
		}

		setStoredProfile(profileDataToStore)

		return profileDataToStore
	}

	return undefined
}

/**
 * Authenticate with a previous session.  This will attempt to get a new UACAPI token
 * using the refresh token from the previous session.
 * @param locale
 * @param session
 * @returns
 */
async function authenticateWithPreviousSession(
	locale: string,
	session?: UserSessionInfo,
): Promise<UserSessionInfo | undefined> {
	// Clear the profile stored in local storage
	setStoredProfile(undefined)

	if (session?.accountType === CustomerAuthType.REGISTERED) {
		if (!session.idm) {
			throw new AuthenticationError('Session is missing IDM token information')
		}

		const refreshResponse = await fetch('/api/auth/v2/refresh/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ refreshToken: session.idm.refreshToken }),
		})

		if (!refreshResponse.ok) {
			const errorPayload = await refreshResponse.json()
			throw new AuthenticationError('idm', `Unable to refresh IDM token: ${errorPayload.error}`)
		}

		const idmTokens = await zodParseBodyJson(AuthRefreshReponseSchema, refreshResponse)

		// Use the new IDM tokens to get a new UACAPI token
		const uacapiToken = await uacapiAuthRegistered({
			locale,
			accessToken: idmTokens.accessToken,
			refreshToken: idmTokens.refreshToken,
			accessTokenExpiration: idmTokens.expiresIn,
			previousToken: session.uacapi.accessToken,
		})

		if (!uacapiToken) {
			throw new AuthenticationError('uacapi', 'Unable to get exchange the IDM token for a registered user UACAPI token')
		}

		const newSession = {
			accountType: CustomerAuthType.REGISTERED,
			customerNo: uacapiToken.decodedToken?.subjectDecoded.customerNo || '',
			uacapi: {
				accessToken: uacapiToken.accessToken,
				expiresAt: new Date(uacapiToken.expiresAt),
				iat: uacapiToken.decodedToken?.payload.iat,
			},
			idm: {
				accessToken: idmTokens.accessToken,
				refreshToken: idmTokens.refreshToken,
				expiresAt: new Date(idmTokens.expiresIn),
			},
		}

		storeSessionData(newSession)

		return newSession
	}

	return authenticateAsGuest(locale, session)
}

async function getSession(locale: string): Promise<UserSessionInfo | undefined> {
	let session = getStoredSession()

	if (!session) {
		// If we don't have a session, then we'll try to get one from the server
		//  and store it in local storage.
		session = await authenticateAsGuest(locale)
	} else if (session?.accountType === CustomerAuthType.REGISTERED) {
		if (!session.idm) {
			throw new AuthenticationError('Session is missing IDM token information')
		}

		const isIdmExpired = isTokenExpired(session.idm.expiresAt)
		const isUacapiExpired = isTokenExpired(session.uacapi.expiresAt)

		// this expiration is for the IDM accessToken
		// if it expires we can do a refresh IDM flow
		// there is no expiration returned from IDM for the Refresh token
		// so we can only action if the call fails with a try/catch
		if (isIdmExpired) {
			try {
				session = await authenticateWithPreviousSession(locale, session)
			} catch {
				// In the case that the IDM refresh token is expired,
				// we have no choice but to log the get a guest user
				// session and log the user out.
				session = await authenticateAsGuest(locale)
			}
		} else if (isUacapiExpired) {
			// If the UACAPI token is expired, then we'll try to get a new one.
			session = await authenticateWithPreviousSession(locale, session)
			storeSessionData(session)
		}
	} else if (session?.accountType === CustomerAuthType.GUEST) {
		const isUacapiExpired = isTokenExpired(session.uacapi.expiresAt)

		// if Guest token is expired the session is gone just make a new one
		if (isUacapiExpired) {
			session = await authenticateAsGuest(locale)
			storeSessionData(session)
		} else {
			// Guest token refresh no sooner than
			// 5 min after issuance to reduce overhead
			const uacapiTokenIssuedAt: Date | undefined = session?.uacapi.iat
				? new Date(session.uacapi.iat * 1000)
				: undefined
			if (uacapiTokenIssuedAt && isTokenCreatedAfterBuffer(uacapiTokenIssuedAt)) {
				session = await authenticateAsGuest(locale, session)
				storeSessionData(session)
			}
		}
	}

	return session
}

async function signOut(locale: string): Promise<UserSessionInfo | undefined> {
	// Invalidate sensitive localStorage data
	// TODO: This needs to be handled differently.  We will probably
	//	want to have some pub/sub so that different parts of the app
	//	can respond to updated data (like this) and update their state
	//	Something redux like would be good for this.
	localStorage.removeItem(IDME_STORAGE_KEY)
	localStorage.removeItem(CUSTOMER_BOPIS_CONTACT_STORAGE_KEY)
	localStorage.removeItem(CUSTOMER_PHONE_STORAGE_KEY)

	// use Promise.all to run parallel calls to UACAPI to end the previous and create a new session
	const asyncMethods: Promise<UserSessionInfo | undefined | void>[] = [authenticateAsGuest(locale)]

	// send the UACAPI accessToken so that
	// UACAPI can delete the session to free
	// up the resources before creating a new one
	// wrapped in a try/catch because errors
	// should not prevent creating the session
	const previousSession = getStoredSession()
	if (previousSession) asyncMethods.push(uacapiLogout(previousSession.uacapi.accessToken))

	const [guestSession] = await Promise.all(asyncMethods)
	if (!guestSession) {
		logger.error('Unable to sign out because we were unable to determine the guest session')
		return undefined
	}

	return guestSession
}

export { authenticateWithEmail, authenticateWithPreviousSession, getSession, getUserProfile, registerUser, signOut }

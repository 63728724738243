export const states: Record<string, Array<string | [string, string]>> = {
	US: [
		'AL',
		'AK',
		'AS',
		'AZ',
		'AR',
		'AA',
		'AE',
		'AP',
		'CA',
		'CO',
		'CT',
		'DE',
		'DC',
		'FL',
		'GA',
		'GU',
		'HI',
		'ID',
		'IL',
		'IN',
		'IA',
		'KS',
		'KY',
		'LA',
		'ME',
		'MD',
		'MA',
		'MI',
		'MN',
		'MP',
		'MS',
		'MO',
		'MT',
		'NE',
		'NV',
		'NH',
		'NJ',
		'NM',
		'NY',
		'NC',
		'ND',
		'OH',
		'OK',
		'OR',
		'PA',
		'PR',
		'RI',
		'SC',
		'SD',
		'TN',
		'TX',
		'UT',
		'VT',
		'VI',
		'VA',
		'WA',
		'WV',
		'WI',
		'WY',
	],
	CA: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
	IE: [
		['CK', 'Cork'],
		['CL', 'Clare'],
		['CV', 'Cavan'],
		['CW', 'Carlow'],
		['DB', 'Dublin'],
		['DG', 'Donegal'],
		['FE', 'Fermanagh'],
		['GW', 'Galway'],
		['KD', 'Kildare'],
		['KK', 'Kilkenny'],
		['KY', 'Kerry'],
		['LF', 'Longford'],
		['LI', 'Limerick'],
		['LM', 'Leitrim'],
		['LS', 'Laois'],
		['LT', 'Louth'],
		['MH', 'Monaghan'],
		['MT', 'Meath'],
		['MY', 'Mayo'],
		['OF', 'Offaly'],
		['RC', 'Rosscommon'],
		['SG', 'Sligo'],
		['TP', 'Tipperary'],
		['WF', 'Waterford'],
		['WK', 'Wicklow'],
		['WM', 'Westmeath'],
		['WX', 'Wexford'],
	],
}

export const DEFAULT_LINE_ITEM_QUANTITY_LIMIT = 10
export const SCENE7_BASEURL = 'https://underarmour.scene7.com/is/'
export const IDME_STORAGE_KEY = 'idMeToken'
export const CUSTOMER_PHONE_STORAGE_KEY = 'customerPhoneNumber'
export const PROMPTED_LOGIN_STORAGE_KEY = 'prompted_login'
export const CUSTOMER_BOPIS_CONTACT_STORAGE_KEY = 'customerBopisContact'
export const DEFAULT_SORT_RULE = 'now-trending'
export const DYNAMIC_PARAMS_SEGMENT_KEY = 'searchParams'
export const LOYALTY_VISITED_STORAGE_KEY = 'loyalty_visited'
export const ROOT_NAVIGATION_ID = 'root'
export const CART_WISHLIST_PRODUCTS_LIMIT = 3

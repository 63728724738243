import { ensureString } from 'types/strict-null-helpers'
import { getNonLocaleURLSegments } from './i18n/urls'
import type { ParsedUrlQuery } from 'querystring'
import querystring from 'querystring'
import { DYNAMIC_PARAMS_SEGMENT_KEY } from './constants'
import { getDefaultLocale, getLanguageByLocale } from './i18n/locale'

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop<T = void>(): T {
	return undefined as unknown as T
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const asyncNoop = async () => {}
export interface UrlObject {
	host: string
	pathname?: string | null
	query?: ConstructorParameters<typeof URLSearchParams>[0]
}

const REGEX_EXTERNAL_URL = /^(?:[a-z+]+:)?\/\//i
/**
 * Determines if the given url is a reference to an external link
 * @param href The href to check
 * @returns
 */
export const isExternalLink = (href: string) => REGEX_EXTERNAL_URL.test(href)

export function formatUrl(urlObject: UrlObject): string {
	const newUrl = new URL(urlObject.host)

	if (urlObject.pathname) {
		newUrl.pathname = urlObject.pathname
	}
	if (urlObject.query) {
		newUrl.search = new URLSearchParams(urlObject.query).toString()
	}
	return newUrl.toString()
}

/**
 * Returns the just the path portion of the given url along with the query params.
 * 	For example, if you pass in:
 * 		https://www.example.com/en-us/c/mens/bottoms?start=4&srule=best-sellers
 * 	the function will return:
 * 		/en-us/c/mens/bottoms?start=4&srule=best-sellers
 * @param url The full url with hostname
 * @returns
 */
export function getPathAndParamsFromUrl(url: string): string {
	const ob = new URL(url)
	return addTrailingSlash(`${ob.pathname}${ob.search}`)
}

/**
 * This will update the query params in the given url with the ones specified
 * in the queryParams variable.  Note that it will leave any of the params that
 * are not in the `queryParams` object as-is in the final path.
 *
 * For example, if you pass in:
 * 	urlPathWithParams = "/c/mens/bottoms?start=4&srule=best-sellers"
 * 	queryParms = {
 * 		start: null,
 * 		srule: "top-rated",
 * 		foo: 1,
 * 	}
 *
 * 	the final url will be:
 *
 * 	"/c/mens/bottoms?srule=top-rated&foo=1"
 *
 * @param urlPathWithParams The relative path in the URL with the query params
 * @param updatedParams The query parameters to update in the final string (as an object)
 * @returns A string with the given path and the updated params.
 */
export function updateUrlParams(urlPathWithParams: string, updatedParams: Record<string, string | null>): string {
	const [pathname, previousParams] = urlPathWithParams.split('?')
	const originalParams = new URLSearchParams(previousParams)

	const finalParams = updateQueryParams(originalParams, updatedParams)
	return `${pathname}${finalParams.toString() && `?${finalParams}`}`
}

export function updateQueryParams(params: URLSearchParams, updatedParams: Record<string, string | null>) {
	const finalParams = new URLSearchParams(params)
	Object.entries(updatedParams).forEach(([key, value]) => {
		if (value === null) finalParams.delete(key)
		else {
			finalParams.set(key, value)

			// If updating `viewPreference`, also update the size filter
			if (key === 'viewPreference') {
				const sizePref = Array.from(finalParams.entries()).find((param) => param[1] === 'size')

				if (sizePref) {
					const sizeKey = `prefv${sizePref[0].replace('prefn', '')}`
					const currentSize = finalParams.get(sizeKey)

					if (!currentSize?.includes('|')) {
						finalParams.set(sizeKey, value)
					} else if (!currentSize?.includes(value)) {
						finalParams.set(sizeKey, `${currentSize}|${value}`)
					}
				}
			}
		}
	})
	return finalParams
}

/**
 * This will extract a segment with the given name from the given path.
 * For example, if you pass in:
 * 	path = "/c/mens/bottoms"
 * 	segment = "mens"
 * 	the final path will be:
 * 	"/c/bottoms"
 * @param path The path to extract the segment from
 * @param segment One or more segments to remove
 * @returns The path with the segment removed
 */
export function extractPathSegments(path: string, segmentsToRemove: string[]): string {
	return path
		.split('/')
		.filter((s) => !segmentsToRemove.includes(s))
		.join('/')
}

/**
 * Takes a path and converts to an array of segments, removing any empty segments
 * @param path The path segments as an array taking filtering out empty segments
 * @returns
 */
export function getPathSegmentsAsArray(path: string): string[] {
	return path.split('/').filter((s) => s)
}

/**
 * Converts a query string to an object
 * @param q The query string in the form "a=1&b=2" or "?a=1&b=2"
 * @returns {Object} The query string as an object, e.g. {a: "1", b: "2"}
 */
export function queryStringToObject(q: string): Record<string, string> {
	if (['', 'none', 'nil', 'null'].includes(q)) {
		return {}
	}
	const paramsAsStringDecoded = decodeURIComponent(q)
	return Object.fromEntries(new URLSearchParams(paramsAsStringDecoded)) || {}
}

/**
 * Parses a cookies string and returns the results as a plain object.
 * @param cookiesAsString A string of cookie data as received in a "Cookie" header
 * @returns {Object} The cookies as a dictionary
 */
export function parseCookieString(cookiesAsString: string) {
	if (!cookiesAsString) {
		return {}
	}

	return cookiesAsString.split(';').reduce((prev, current) => {
		const [name, ...value] = current.split('=')
		// eslint-disable-next-line no-param-reassign
		prev[name.trim()] = value.join('=').trim()
		return prev
	}, {})
}

type ErrorWithMessage = {
	message: string
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
	return (
		typeof error === 'object' &&
		error !== null &&
		'message' in error &&
		typeof (error as Record<string, unknown>).message === 'string'
	)
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
	if (isErrorWithMessage(maybeError)) return maybeError

	try {
		return new Error(JSON.stringify(maybeError))
	} catch {
		// fallback in case there's an error stringifying the maybeError
		// like with circular references for example.
		return new Error(String(maybeError))
	}
}

export function getErrorMessage(error: unknown) {
	return toErrorWithMessage(error).message
}

const REGEX_URL_WITH_TRAILING_SLASH = /\/$/
const REGEX_FILE_URL_WITH_TRAILING_SLASH = /\/[^/]+\.[a-zA-Z0-9]{1,5}\/$/

/**
 * Removes trailing slash from a url (if one is there)
 * @param url The original url
 * @returns The new url
 */
export function removeTrailingSlash(url: string, onlyRemoveIfEndsWithFileExtension = false) {
	const [pathname, search] = url.split('?')
	if (
		(onlyRemoveIfEndsWithFileExtension ? REGEX_FILE_URL_WITH_TRAILING_SLASH : REGEX_URL_WITH_TRAILING_SLASH).test(
			pathname,
		)
	) {
		return `${pathname.slice(0, -1)}${search ? `?${search}` : ''}`
	}
	return url
}

/**
 * Handles adding a trailing slash if there isn't already one there.  This will
 * also take into account paths that end in a file extension, and not add a
 * trailing slash in that case. For example:
 *  "/c/mens/bottoms" => "/c/mens/bottoms/"
 *  "/c/mens/bottoms?color=blue" => "/c/mens/bottoms/?color=blue"
 *  "/c/mens/bottoms.html" => "/c/mens/bottoms.html"
 * @param url
 * @returns
 */
export function addTrailingSlash(url: string) {
	const [pathname, search] = url.split('?')
	if (/.*\.[a-zA-Z0-9]{1,5}$/.test(pathname)) {
		// if it has a file extension, don't add a trailing slash
		return url
	}

	if (pathname.at(-1) === '/') {
		// if it already has a trailing slash, don't add another one
		return url
	}

	return `${pathname}/${search ? `?${search}` : ''}`
}

/**
 * Makes a deep copy of an object by first stringifying it, and then `JSON.parse`ing it.
 * See https://developer.mozilla.org/en-US/docs/Glossary/Deep_copy and
 * https://github.com/ua-digital-commerce/seabiscuit-pwa/pull/723#discussion_r1008404288
 * @param object the object to make a deep copy of
 * @returns a deep copy of the object
 */
export function deepCopy<T>(object: T): T {
	return JSON.parse(JSON.stringify(object))
}

/**
 * This will compare two arrays to match the containing objects to make sure they contain the same
 * data. This will return false if the array orders are different as well in most cases.
 */
export function deepStringArrayCompare(a: string[], b: string[]): boolean {
	if (a.length !== b.length) return false
	return a.join() === b.join()
}

export function deepObjectCompare<T>(a: T, b: T): boolean {
	return JSON.stringify(a) === JSON.stringify(b)
}

export function deepObjectArrayCompare<T>(a: T[], b: T[]): boolean {
	return JSON.stringify(a) === JSON.stringify(b)
}

// example input:
// "Monday - 10:00AM - 8:00PM\nTuesday - 10:00AM - 8:00PM\nWednesday - 10:00AM - 8:00PM\nThursday - 10:00AM - 9:00PM\nFriday - 10:00AM - 9:00PM\nSaturday - 10:00AM - 9:00PM\nSunday - 10:00AM - 7:00PM\n"
type StoreHours = { timeframe: [string, string] | [string]; hours: string }[]

export function parseHoursString(storeHours: string): StoreHours {
	const weeklyHours = storeHours
		.split('\n')
		.filter((x) => !!x)
		.map((dayHours) => {
			const [day, openTime, closeTime] = dayHours.split(' - ')
			return { day: day.toLowerCase(), hours: `${openTime} - ${closeTime}` }
		})
	return weeklyHours.reduce(
		(previous: StoreHours, current, idx) => {
			if (idx === 0) return previous
			if (previous.at(-1)?.hours === current.hours) {
				const newLast = previous.pop()
				if (newLast) {
					if (newLast.timeframe.length === 1) {
						newLast.timeframe.push(current.day)
					} else if (newLast.timeframe?.length > 1) {
						newLast.timeframe[1] = current.day
					}
					previous.push(newLast)
				}
				return previous
			}
			previous.push({ timeframe: [current.day], hours: current.hours })
			return previous
		},
		[{ timeframe: [weeklyHours[0].day], hours: weeklyHours[0].hours }],
	)
}

export const iterateObject = (ob: object, clb: (key: string, val: unknown) => unknown) => {
	const iterate = (obj: object) => {
		Object.keys(obj).forEach((key) => {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				iterate(obj[key])
			} else {
				const newValue = clb(key, obj[key])
				// eslint-disable-next-line no-param-reassign
				obj[key] = newValue
			}
		})
	}
	iterate(ob)
}

// Useful for getting the value of a url query param,
// when the code only supports a single value for the param.
// The problem is that any query param can ALWAYS be an array; there's no way to stop it.
// Users can just add "q1=this&q1=that" in the addressbar.
export const firstStringValue = (obj: unknown): string => ensureString(Array.isArray(obj) ? obj[0] : obj)

/**
 * Gets all the path segments after the locale and the page type identifiers.
 */
export const getPathSegmentsAfterDescriptors = (path: string) =>
	getNonLocaleURLSegments(path).filter((segment) => !['c', 't', 'p'].includes(segment))

export const isString = (value: unknown): value is string => typeof value === 'string'

/**
 * The is an **in-place** replacement of a given object that removes properties that are deemed unnecessary.
 * This can be helpful in cases where a graphql query is shared between two use-cases and only some of the
 * data is required.  And only in the case where that data needs to be transferred over the wire between
 * server and client.  The list of properties to remove take the following format:
 *
 * 	All properties with a given name - this will remove all properties named this regardless of where they
 * 	are in the hierarchy.
 * 		`mypropname`
 *
 * 	All properties named `test` within an object called `myobj`:
 * 		`myobj.test`
 *
 *  All properties named `test` within an object inside of an array named `myarray`
 * 		`myarray.[].test`
 *
 * 	Other examples:
 * 		`root.myarray.[].nestedarray.[].test`
 *
 * 	This would remove `test` from this:
 * 	```json
 * 	{
 * 		root: {
 * 			myarray: [
 * 				{
 * 					nestedarray: [
 * 						{
 * 							test: "deleted"
 * 						}
 * 					]
 * 				}
 * 			]
 * 		}
 * 	}
 * 	```
 *
 * @param ob The object to mutate
 * @param propertiesToStrip This is a string array of paths to the data to delete.  (see above)
 * @param path The Current
 * @returns
 */
export function stripUnnecessaryData(
	ob: Record<string, unknown>,
	propertiesToStrip: string[],
	path: string | undefined,
) {
	if (!ob) {
		return
	}

	Object.keys(ob).forEach((key) => {
		const currentPath = `${path ? `${path}.` : ''}${key}`

		if (propertiesToStrip.find((p) => p === currentPath || p === key)) {
			// eslint-disable-next-line no-param-reassign
			delete ob[key]
			return
		}

		if (Array.isArray(ob[key])) {
			const arrayPath = `${currentPath}.[]`
			;(ob[key] as Array<unknown>).forEach((i) => {
				if (i && typeof i === 'object') {
					stripUnnecessaryData(i as Record<string, unknown>, propertiesToStrip, arrayPath)
				}
			})
			return
		}

		if (typeof ob[key] === 'object') {
			stripUnnecessaryData(ob[key] as Record<string, unknown>, propertiesToStrip, currentPath)
		}
	})
}
// Removes dollar sign from string maintaining commas, periods and hyphens
export const removeDollarSignFromString = (inputString: string) => {
	if (!inputString) return undefined
	return inputString.replace(/[^0-9,.-]+/g, '')
}

export const formatDate = (locale: string, date: string, options?: Intl.DateTimeFormatOptions) =>
	new Date(date).toLocaleDateString(locale, {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		...options,
	})

const phoneRegex = /^(\d{0,3})(\d{0,3})(\d{0,4})$/
const nonDegitsRegex = /[^\d]/g

export const formatPhoneNumber = (str: string) => {
	const onlyNumbers = str.replaceAll(nonDegitsRegex, '')
	return onlyNumbers.replace(phoneRegex, (_, $1, $2, $3) => [$1, $2, $3].filter((group) => Boolean(group)).join('-'))
}

export const stripPhoneNumber = (phone: string) => phone.replaceAll(/\D/g, '')

/**
 * This will check whether a given url path contains all 3 data points to construct a sku.
 * Example input: /product/123456.html?dwvar_123456_size=LG&dwvar_123456_color=123
 * Example output: 123456-123-LG
 * @param path the url path to check
 * @returns true if the path contains all 3 data points, false otherwise undefined
 */

/**
 * Determines if unknown error is instance of Error,
 * otherwise returns new unknown error with optional message.
 */
export function safeError(error: unknown, fallbackMessage = 'Unknown error'): Error {
	if (error instanceof Error) {
		return error
	}
	return new Error(fallbackMessage)
}

/**
 * Safely parse JSON from a string without throwing an error. Returns undefined if an error occurs
 * @param text
 * @returns T | undefined
 */
export function safeJsonParse<T>(text: string) {
	let result: ReturnType<JSON['parse']>
	try {
		result = JSON.parse(text)
	} catch {
		result = undefined
	}
	return result as T | undefined
}

/**
 * It's hard to determine if something is a ParsedUrlQuery because it's just a Record<string, string | string[]>, but
 * we can narrow it based on commonly used alternatives like strings and URLSearchParams.  If it's not one of those,
 * then we can assume it's a ParsedUrlQuery although it's not a perfect check.
 * @param query
 * @returns
 */
export function isParsedUrlQuery(query: unknown): query is ParsedUrlQuery {
	return query !== null && typeof query === 'object' && !Array.isArray(query) && !('getAll' in (query as object))
}

/**
 * Type guard for URLSearchParams
 * @param query
 * @returns
 */
export function isUrlSearchParams(query: unknown): query is URLSearchParams {
	if (query && typeof query === 'object' && 'getAll' in query) {
		return true
	}
	return false
}

/**
 * This will join a path and search params together, ensuring that the search params are properly
 * formatted.  It accepts any format for the query parameters and does the right thing.
 * @param path The path without query params
 * @param search The search params in the form of a URLSearchParams object, a Record<string, string>, or a string
 * @returns
 */
export function joinPathAndSearchParams(path: string, search?: URLSearchParams) {
	const searchParamsAsString = search?.toString() || ''
	const appendedParams = searchParamsAsString ? `?${searchParamsAsString}` : ''

	// just in case path has query params in it
	return `${path.split('?')[0]}${appendedParams}`
}

/**
 * Takes any number and combination of query parameters and merges them into a single
 * list for use in a URLSearchParams object. Array values are merged into a single
 * array as opposed to replacing one array with another.
 * @param params
 * @returns
 */
export function combineParamsAndMergeArrays(
	...paramDicts: Array<URLSearchParams | Record<string, string | string[]>>
): URLSearchParams {
	const paramObjects: Array<Record<string, string | string[] | undefined>> = paramDicts.map((params) => {
		if (!isUrlSearchParams(params)) {
			return params
		}
		return urlSearchParamsToParsedUrlQuery(params)
	})

	const mergedParams: Record<string, string | string[] | undefined> = {}
	paramObjects.forEach((params) => {
		Object.entries(params).forEach(([key, value]) => {
			if (key in mergedParams) {
				// Ensure the existing entry for key is an array
				if (!Array.isArray(mergedParams[key])) {
					if (Array.isArray(value)) {
						// If the new value is an array then replace the existing value with an array
						mergedParams[key] = [mergedParams[key] as string, ...value]
					} else {
						mergedParams[key] = value
					}
				} else {
					// eslint-disable-next-line no-lonely-if
					if (Array.isArray(value)) {
						// If the new value is an array then just replace the array.
						mergedParams[key] = [...(mergedParams[key] as string[]), ...value]
					} else if (typeof value === 'string') {
						// If the new value is a string then push it to the array.
						;(mergedParams[key] as string[]).push(value)
					}
				}
			} else {
				// Insert new key-value pair, directly or as an array
				mergedParams[key] = value
			}
		})
	})
	return parsedUrlQueryToUrlSearchParams(mergedParams)
}

/**
 * This will return the given domain with the protocol prepended to it.  If the domain
 * already has a protocol, then it will be left as-is.  It also uses a non-ssl protocol
 * for localhost domains.
 * @param domain
 * @returns
 */
export function makeUriFromDomain(domain: string) {
	if (domain.startsWith('http')) {
		return domain
	}

	return `http${domain.startsWith('localhost') ? '' : 's'}://${domain}`
}

/**
 * Checks if a URL is external.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} `true` if the URL is external, `false` otherwise.
 */
export function isExternalUrl(url: string): boolean {
	return url.startsWith('http')
}

/**
 * This is a type predicate that will narrow the type of the given object to a Record<string, unknown>
 * only if the object has keys.
 * @param obj
 * @returns
 */
export function isObjectWithKeys(obj: unknown): obj is Record<string, unknown> {
	return typeof obj === 'object' && obj !== null && Object.keys(obj).length > 0
}

/**
 * Converts a ParsedUrlQuery to a URLSearchParams object taking into account query
 * items that indicate an array.  For example, if the query is:
 * 	{ a: '1', b: ['2', '3'] }
 * 	the resulting URLSearchParams will be:
 * 	{ a: '1', b: '2', b: '3' }
 *
 * @param query
 * @returns
 */
export function parsedUrlQueryToUrlSearchParams(query: ParsedUrlQuery) {
	return new URLSearchParams(querystring.stringify(query))
}

/**
 * Converts a URLSearchParams object to a ParsedUrlQuery taking into account query
 * items that indicate an array.  For example, if the query is:
 * 	{ a: '1', b: '2', b: '3' }
 * 	the resulting ParsedUrlQuery will be:
 * 	{ a: '1', b: ['2', '3'] }
 * @param searchParams
 * @returns
 */
export function urlSearchParamsToParsedUrlQuery(searchParams: URLSearchParams): ParsedUrlQuery {
	return querystring.parse(searchParams.toString())
}

/**
 * Converts a RecordSet to a URLSearchParams object taking into account query
 * items that indicate an array.  For example, if the query is:
 * 	{ a: '1', b: ['2', '3'] }
 * 	the resulting URLSearchParams will be:
 * 	{ a: '1', b: '2', b: '3' }
 * @param recordSet
 * @returns
 */
export function recordSetToUrlSearchParams(recordSet: Record<string, string | string[]>) {
	const searchParams = new URLSearchParams()
	Object.entries(recordSet).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			value.forEach((v) => searchParams.append(key, v))
		} else {
			searchParams.append(key, value)
		}
	})
	return searchParams
}

/**
 * Adds the specified parameter to the search params if it exists in the dynamic parameters.
 * @param input - The dynamic parameters input.
 * @param paramName - The name of the parameter to add.
 * @param params - The URLSearchParams object to add the parameter to.
 */
function addParamFromDynamicParameters(
	input: Record<string, string | string[] | undefined> | URLSearchParams | null,
	paramName: string,
	params: URLSearchParams,
	defaultValue?: string,
): void {
	const paramValue = isUrlSearchParams(input) ? input.getAll(paramName) : input?.[paramName]
	if (paramValue) {
		if (Array.isArray(paramValue)) {
			paramValue.forEach((value) => params.append(paramName, value))
		} else {
			params.append(paramName, paramValue)
		}
	} else if (defaultValue) {
		params.append(paramName, defaultValue)
	}
}

export function extractSearchParamsFromDynamicParameters(
	input: Record<string, string | string[] | undefined> | URLSearchParams | null,
): URLSearchParams {
	const searchParams = isUrlSearchParams(input)
		? input.get(DYNAMIC_PARAMS_SEGMENT_KEY)
		: input?.[DYNAMIC_PARAMS_SEGMENT_KEY]
	if (typeof searchParams === 'undefined' || searchParams === null) {
		// In the case that there are no search params then assume that the input is itself the search params
		if (!input) {
			return new URLSearchParams()
		}
		return isUrlSearchParams(input) ? input : parsedUrlQueryToUrlSearchParams(input)
	}

	// Add the decoded search params to a URLSearchParams object
	const decodedSearchParams = Array.isArray(searchParams)
		? searchParams.map(decodeURIComponent).join('&')
		: decodeURIComponent(searchParams)
	const params = new URLSearchParams(decodedSearchParams)
	new URLSearchParams(params).forEach((val, key) => !val && params.delete(key))

	// Now add the cid to the search params
	addParamFromDynamicParameters(input, 'cid', params)

	// Now add the locale to the search params - we will always add a locale
	//  because there are no (non-api) paths that are valid without a locale.
	addParamFromDynamicParameters(input, 'locale', params, getDefaultLocale())

	// This will add the productId to the search params if it exists in the dynamic
	//  parameters.  Note that this function is used by both product list and product detail
	//  pages, so it's possible that the productId will be in the dynamic parameters.
	addParamFromDynamicParameters(input, 'productId', params)

	return params
}

/**
 * Returns the size value passed in via valid dwvar size parameter
 * If a URL contains the following search params "dwvar_1290261_size=LG&dwvar_1290261_color=001"
 * this function will return "LG".
 * If size param is not present, or in invalid format, this will return undefined
 * If size param is passed but no value, this will return null
 * @param searchParams - searchParams: URLSearchParams
 * @returns size string
 */
export function getSizeInUrl(searchParams: URLSearchParams): string | null | undefined {
	const sizeRegex = /^dwvar_\d+_size$/
	const sizeParam = searchParams && Array.from(searchParams.keys()).find((key) => sizeRegex.test(key))
	return sizeParam && (searchParams.get(sizeParam) || null)
}

export function maybeObject<T>(key: string, value: T) {
	return typeof value !== 'undefined' && value !== null ? { [key]: value } : {}
}

const units: { unit: Intl.RelativeTimeFormatUnit; ms: number }[] = [
	{ unit: 'year', ms: 31536000000 },
	{ unit: 'month', ms: 2628000000 },
	{ unit: 'day', ms: 86400000 },
	{ unit: 'hour', ms: 3600000 },
	{ unit: 'minute', ms: 60000 },
	{ unit: 'second', ms: 1000 },
]

/**
 * Get locale-specific relative time message from elapsed time.
 * @param elapsed - the elapsed time in milliseconds
 */
export function relativeTimeFromElapsed(locale: string, elapsed: number) {
	const rtf = new Intl.RelativeTimeFormat(getLanguageByLocale(locale), { numeric: 'auto' })
	let result = ''
	for (let i = 0; i < units.length; i++) {
		const { ms, unit } = units[i]
		if (Math.abs(elapsed) >= ms || unit === 'second') {
			result = rtf.format(Math.round(elapsed / ms), unit)
			break
		}
	}
	return result
}

/**
 * Get locale-specific relative time message from Date.
 * @param relative - the relative dateTime
 * @param pivot - the dateTime of reference, generally is the current time
 */
export function relativeTimeFromDate(locale: string, relative: Date | null, pivot: Date = new Date()) {
	if (!relative) return ''
	const elapsed = relative.getTime() - pivot.getTime()
	return relativeTimeFromElapsed(locale, elapsed)
}

import type { CommerceConfiguration } from '~/graphql/generated/uacapi/type-document-node'
import type { EnvironmentModel } from 'flagsmith-nodejs'
import type {
	FeatureFlagsConfiguration,
	FrontendConfiguration,
} from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import type { FlagsmithFeatureFlags } from './types/flagsmith-features.interface'
import Cookies from 'universal-cookie'
import { constructAbTestList } from './client-server/abtest'
import { getConfigFeatureFlags } from './types/config-features.interface'

/**
 * This is a token applied to keys located inside the featureFlags object
 * It's used to save the original flag value prior to being overridden by the Toolkit UI
 * @example
 * ```
 *   If original flag value has:
 *     featureFlags.hasPdpEnhancedImages: true
 *
 *   then flag values after being overridden using the Toolkit UI will appear as:
 *     featureFlags.hasPdpEnhancedImages: false
 *     featureFlags.hasPdpEnhancedImages__: true
 * ```
 */
export const FF_OVERRIDDEN_VALUE_TOKEN = '__'
export const DEFAULT_FLAGSMITH_FLAG_VALUES: FlagsmithFeatureFlags = {
	hasPdpBazaarvoiceCustomUi: false,
	hasPdpBazaarvoiceHostedUi: true,
	hasPdpBazaarvoiceCustomQa: false,
	hasPdpEnhancedBuyPanel: false,
	hasPdpEnhancedImages: true,
	hasPdpEnhancedProductDetails: false,
	hasPdpEnhancedProductName: true,
	hasPdpLargeImageSwatch: false,
	hasPdpRemoveDefaultSizing: false,
	hasPdpEnhancedContent: false,
	hasPdpJumplink: false,
}

/**
 * This will return a cleaned object of feature flags, provided from UACAPI
 * and Flagsmith.
 */
export function getFeatureFlags({
	commerceConfiguration,
	flagsmithEnvironment,
}: {
	commerceConfiguration?: CommerceConfiguration | null
	flagsmithEnvironment?: EnvironmentModel | null
}): FeatureFlagsConfiguration | undefined {
	let flagsmithFlags: FlagsmithFeatureFlags | null = null
	if (flagsmithEnvironment && flagsmithEnvironment?.featureStates?.length > 0) {
		flagsmithFlags = flagsmithEnvironment.featureStates.reduce(
			(accumulator, current) => ({
				...accumulator,
				[current.feature.name]: current.enabled ?? false,
			}),
			{},
		) as FlagsmithFeatureFlags
	} else if (!flagsmithEnvironment) {
		// There is a minute chance that our Flagsmith Server is out of service before we have
		// been able to hit the API and cache flag values. While it is not expected to be used,
		// this logic safeguards us from this experience, setting default flag values that will
		// persist until we are able to successfully get our values from the FS API.
		flagsmithFlags = DEFAULT_FLAGSMITH_FLAG_VALUES
	}

	return commerceConfiguration?.featureFlags && flagsmithFlags
		? {
				...commerceConfiguration.featureFlags,
				...flagsmithFlags,
				...getConfigFeatureFlags(),
		  }
		: undefined
}

/**
 * Returns a list of feature flag overrides specified in the search params or cookie.
 * @returns
 */
export function getFeatureFlagOverrideList() {
	const fromCookies = new Cookies(document.cookie).get('ff')?.split(',') || []
	const fromQuery = new URLSearchParams(window.location.search).get('ff')?.split(',') || []
	return constructAbTestList({}, {}, fromCookies, fromQuery, []) as Record<string, boolean | undefined>
}

/**
 * Returns a new frontend configuration object containing feature flags and override values.
 * @param config
 * @param featureFlagOverrides
 * @returns
 */
export function getFrontendConfigurationWithOverrides(
	config: FrontendConfiguration,
	featureFlagOverrides: FrontendConfiguration['featureFlagOverrides'],
) {
	const featureFlags = JSON.parse(JSON.stringify(config.commerceConfig?.featureFlags ?? {}))
	Object.entries(featureFlagOverrides).forEach(([key, value]) => {
		if (featureFlags && key) {
			featureFlags[`${key}${FF_OVERRIDDEN_VALUE_TOKEN}`] = featureFlags[key]
			featureFlags[key] = value
		}
	})
	return {
		...config,
		featureFlagOverrides,
		commerceConfig: config.commerceConfig
			? {
					...config.commerceConfig,
					featureFlags,
			  }
			: undefined,
	}
}

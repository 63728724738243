export enum PaymentMethod {
	AurusCreditCard = 'AurusCreditCardPaymentInstrument',
	Paymetric = 'PaymetricPaymentInstrument',
	Klarna = 'KlarnaPaymentInstrument',
	PayPal = 'PayPalPaymentInstrument',
	ApplePay = 'ApplePayPaymentInstrument',
	GiftCard = 'GiftCardPaymentInstrument',
	VIP = 'VipPaymentInstrument',
}

/** Track paypal error type */
export enum PaypalErrorType {
	BILLING_ADDRESS_ERROR = 'BILLING_ADDRESS_ERROR',
	CART_PAYPAL_ERROR = 'CART_PAYPAL_ERROR',
}

export enum PayPalTranslationKey {
	BILLING_ADDRESS_ERROR = 'checkout-paypal-error-billing-address',
	// this is a generic error message for any other error that may occur during the PayPal process
	CART_PAYPAL_ERROR = 'cart-paypal-error',
}

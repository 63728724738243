import type { Address, CustomerAddress } from '~/graphql/generated/uacapi/type-document-node'
import { formatPostalcode } from '~/lib/i18n/postal'
import { getCountryCodeByLocale } from './locale'

export enum SupportedCountryCodes {
	US = 'us',
	CA = 'ca',
	NL = 'nl',
	IE = 'ie',
}

/**
 * A map of countries to address formats (city/state/postal only).
 * Consider [_Address_](https://en.wikipedia.org/wiki/Address) when making new additions.
 */
export const addressFormats = {
	[SupportedCountryCodes.US]: '{city}, {state} {postal}',
	[SupportedCountryCodes.CA]: '{city} {state} {postal}',
	[SupportedCountryCodes.NL]: '{city}, {postal}',
	[SupportedCountryCodes.IE]: '{city}, {state} {postal}',
} as const satisfies Record<string, string>

/**
 * Formats the details of an address based on the user's country.
 * User's country determine from locale or from specifiedCountryCode
 * (Only formats the city/state/postal information.)
 */
export function formatAddressDetails(
	details: Pick<Address | CustomerAddress, 'city' | 'stateCode' | 'postalCode'>,
	locale: string,
	specifiedCountryCode?: string,
): string {
	const { city = '', stateCode = '', postalCode = '' } = details
	const isSpecificCodeValid =
		!!specifiedCountryCode &&
		Object.values(SupportedCountryCodes).includes(specifiedCountryCode as SupportedCountryCodes)
	const finalCountryCode = isSpecificCodeValid
		? (specifiedCountryCode as SupportedCountryCodes)
		: (getCountryCodeByLocale(locale) as SupportedCountryCodes)
	const format = addressFormats[finalCountryCode]

	return format
		.replace('{city}', city ?? '')
		.replace('{state}', stateCode ?? '')
		.replace(
			'{postal}',
			formatPostalcode(postalCode ?? '', (specifiedCountryCode ?? finalCountryCode).toLocaleUpperCase()),
		)
}

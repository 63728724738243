/* eslint-disable max-classes-per-file */
import { z } from 'zod'

import { CustomerAuthType, type FeatureFlags } from '~/graphql/generated/uacapi/type-document-node'
import type { IdmCodeResponse } from '~/lib/types/idm.interface'
import { LoginFailureReasons } from '~/lib/types/user.interface'

export const ZodSessionSchemaV2 = z.object({
	accountType: z.enum([CustomerAuthType.GUEST, CustomerAuthType.REGISTERED]),
	customerNo: z.string(),
	uacfId: z.string().optional(),
	uacapi: z.object({
		accessToken: z.string(),
		expiresAt: z.date(),
		iat: z.number().optional(),
	}),
	idm: z
		.object({
			accessToken: z.string(),
			refreshToken: z.string(),
			expiresAt: z.date(),
		})
		.optional(),
})

export const ZodUserPreferenceSchemaV2 = z.object({
	auth: z
		.object({
			rememberMe: z.boolean(),
			lastEmailUsed: z.string(),
		})
		.optional(),
	welcomeMat: z
		.object({
			regionChecked: z.string(),
		})
		.optional(),
})

export type UserSessionInfo = z.infer<typeof ZodSessionSchemaV2>
export type UserPreferenceInfo = z.infer<typeof ZodUserPreferenceSchemaV2>

export const ZodProfileSchemaV1 = z.object({
	customerGroups: z
		.array(
			z.object({
				id: z.string(),
				UUID: z.string().optional(),
			}),
		)
		.optional(),
	email: z.string(),
	emailHash: z.string().optional().describe('Hashed email for use in analytics'),
	uacfId: z.string().optional(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	phone: z.string().optional(),
	postalCode: z.string().optional(),
	isEmployee: z.boolean().optional(),
	isVIP: z.boolean().optional(),
	vip: z
		.object({
			availablePoints: z.number(),
			vipAccountId: z.string(),
		})
		.optional(),
	loyalty: z
		.object({
			id: z.string().nullable().optional(),
			status: z.enum(['ENROLLED', 'UNENROLLED']).optional(),
			statusDate: z.date().optional(),
		})
		.optional(),
	gender: z.enum(['MALE', 'FEMALE']).optional(),
	birthday: z
		.object({
			month: z.number(),
			day: z.number(),
		})
		.optional(),
	selectedStore: z
		.object({
			id: z.string(),
			name: z.string(),
		})
		.optional(),
	uiHints: z
		.object({
			isLoyaltyEnabled: z.boolean().optional(),
			useBOPIS: z.boolean().optional(),
		})
		.optional(),
})

export type UserProfileInfo = z.infer<typeof ZodProfileSchemaV1>

export class AuthenticationError extends Error {
	type: string

	constructor(type: string, message?: string) {
		super(message)
		this.type = type
	}
}

export function isAuthenticationError(error: unknown): error is AuthenticationError {
	return !!(error as AuthenticationError).type
}

export class LoginError extends AuthenticationError {
	loginResponse: IdmCodeResponse

	constructor(loginResponse: IdmCodeResponse) {
		if (loginResponse.errorCode === 'access_denied' && !loginResponse.reasonCode) {
			super('login', LoginFailureReasons.INVALID_CREDENTIALS)
		} else if (loginResponse.errorCode === 'access_denied' && loginResponse.reasonCode === 100) {
			super('login', LoginFailureReasons.FORCED_PASSWORD_RESET)
		} else if (loginResponse.errorCode === 'not_acceptable') {
			super('login', LoginFailureReasons.NOT_ACCEPTABLE)
		} else {
			super('login', LoginFailureReasons.UNKNOWN_FAILURE)
		}

		this.loginResponse = loginResponse
	}
}

export function isLoginError(error: unknown): error is LoginError {
	return !!(error as LoginError).loginResponse
}

/**
 * This class is used to store the user session and profile information
 * Note that it is a class to provide accessors for data that is not entirely
 * clear from the type definitions but also to ensure that code remain the same
 * even if the underlying structures change.  Also note that the class properties
 * are readonly to ensure that the data is not modified outside of this class.  The
 * intention is not to mutate the data in this object after creation.  Instead, replace
 * it with a new object.
 */
export class UserWithSession {
	readonly featureFlags?: Partial<FeatureFlags>
	readonly session: UserSessionInfo
	readonly profile?: UserProfileInfo

	constructor(session: UserSessionInfo, profile?: UserProfileInfo, featureFlags?: Partial<FeatureFlags>) {
		this.featureFlags = featureFlags
		this.session = session
		this.profile = profile
	}

	get isCsr() {
		return !!this.profile?.customerGroups?.some((x) => x?.id?.toUpperCase() === 'CSR')
	}

	get isGuest() {
		return this.session.accountType === 'GUEST'
	}

	get isRegistered() {
		return this.session.accountType === 'REGISTERED'
	}

	get isVip() {
		return (!!this.featureFlags?.isVIPCheckoutExperienceEnabled && this.profile?.isVIP) || false
	}

	get isEmployee() {
		return this.profile?.isEmployee || false
	}

	get isLoyalty() {
		return !!this.featureFlags?.isLoyaltyEnabled && this.profile?.loyalty?.status === 'ENROLLED'
	}

	get firstName() {
		return this.profile?.firstName || ''
	}

	get lastName() {
		return this.profile?.lastName || ''
	}

	get fullName() {
		if (this.profile?.firstName && this.profile?.lastName) {
			return `${this?.profile?.firstName} ${this?.profile?.lastName}`
		}
		if (this.profile?.lastName) {
			return this.profile?.lastName
		}
		if (this.profile?.firstName) {
			return this.profile?.firstName
		}
		return ''
	}

	get email() {
		return this.profile?.email || ''
	}

	get hashedEmail() {
		return this.profile?.emailHash || ''
	}

	get uacfId() {
		return this.profile?.uacfId || ''
	}

	get customerNumber() {
		return this.session.customerNo || ''
	}

	get uacapiAccessToken() {
		return this.session.uacapi?.accessToken
	}

	get idmAccessToken() {
		return this.session.idm?.accessToken
	}
}
